// Generated by Framer (b084a7c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["LWFPj59nM", "uXJ0b4kM8", "skycE4_ng", "dTuU83SoP"];

const variantClassNames = {dTuU83SoP: "framer-v-1jx6v1j", LWFPj59nM: "framer-v-j3z9n4", skycE4_ng: "framer-v-df703o", uXJ0b4kM8: "framer-v-bqv11v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"rgb-gartenunterhalt": "uXJ0b4kM8", "rgb-reinigung": "LWFPj59nM", "weiss-gartenunterhalt": "dTuU83SoP", "weiss-reinigung": "skycE4_ng"}

const getProps = ({height, id, image, link, width, ...props}) => { return {...props, lYcvMoK0f: link ?? props.lYcvMoK0f, PvDVRdprg: image ?? props.PvDVRdprg ?? {src: new URL("assets/i4KWLgRaBd0RfvwjG44uRQ89uyU.svg", import.meta.url).href}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "LWFPj59nM"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, lYcvMoK0f, PvDVRdprg, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "LWFPj59nM", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearufhq61 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("uXJ0b4kM8"), 5000)
})

const onAppear1nin65r = activeVariantCallback(async (...args) => {
await delay(() => setVariant("LWFPj59nM"), 5000)
})

const onAppear1t592bw = activeVariantCallback(async (...args) => {
await delay(() => setVariant("dTuU83SoP"), 5000)
})

const onAppear1u8r195 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("skycE4_ng"), 5000)
})

useOnVariantChange(baseVariant, {default: onAppearufhq61, dTuU83SoP: onAppear1u8r195, skycE4_ng: onAppear1t592bw, uXJ0b4kM8: onAppear1nin65r})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5h6aU", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={lYcvMoK0f}><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 107, intrinsicWidth: 300, pixelHeight: 107, pixelWidth: 300, sizes: "min(156px, 100vw)", ...toResponsiveImage(PvDVRdprg)}} className={`${cx("framer-j3z9n4", className)} framer-go5215`} data-framer-name={"rgb-reinigung"} data-highlight layoutDependency={layoutDependency} layoutId={"LWFPj59nM"} ref={ref} style={{...style}} {...addPropertyOverrides({dTuU83SoP: {"data-framer-name": "weiss-gartenunterhalt", background: {alt: "", fit: "fit", intrinsicHeight: 107, intrinsicWidth: 300, pixelHeight: 107, pixelWidth: 300, src: new URL("assets/QyyGEFSgEdiQCs2FKEB0boXaej8.svg", import.meta.url).href}}, skycE4_ng: {"data-framer-name": "weiss-reinigung", background: {alt: "", fit: "fit", intrinsicHeight: 107, intrinsicWidth: 300, pixelHeight: 107, pixelWidth: 300, src: new URL("assets/a1x092pLcCdslC9G41SJzVn42A.svg", import.meta.url).href}}, uXJ0b4kM8: {"data-framer-name": "rgb-gartenunterhalt", background: {alt: "", fit: "fit", intrinsicHeight: 107, intrinsicWidth: 300, pixelHeight: 107, pixelWidth: 300, src: new URL("assets/yBTJ0DFRoMf16WCcDiaYJIbzYz8.svg", import.meta.url).href}}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5h6aU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5h6aU .framer-go5215 { display: block; }", ".framer-5h6aU .framer-j3z9n4 { height: 56px; overflow: visible; position: relative; text-decoration: none; width: 156px; }", ".framer-5h6aU.framer-v-bqv11v .framer-j3z9n4, .framer-5h6aU.framer-v-df703o .framer-j3z9n4, .framer-5h6aU.framer-v-1jx6v1j .framer-j3z9n4 { aspect-ratio: 2.7857142857142856 / 1; height: var(--framer-aspect-ratio-supported, 56px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 156
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uXJ0b4kM8":{"layout":["fixed","fixed"]},"skycE4_ng":{"layout":["fixed","fixed"]},"dTuU83SoP":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"lYcvMoK0f":"link","PvDVRdprg":"image"}
 */
const FramersO_0ZxrLy: React.ComponentType<Props> = withCSS(Component, css, "framer-5h6aU") as typeof Component;
export default FramersO_0ZxrLy;

FramersO_0ZxrLy.displayName = "logo-rgb";

FramersO_0ZxrLy.defaultProps = {height: 56, width: 156};

addPropertyControls(FramersO_0ZxrLy, {variant: {options: ["LWFPj59nM", "uXJ0b4kM8", "skycE4_ng", "dTuU83SoP"], optionTitles: ["rgb-reinigung", "rgb-gartenunterhalt", "weiss-reinigung", "weiss-gartenunterhalt"], title: "Variant", type: ControlType.Enum}, lYcvMoK0f: {title: "Link", type: ControlType.Link}, PvDVRdprg: {__defaultAssetReference: "data:framer/asset-reference,i4KWLgRaBd0RfvwjG44uRQ89uyU.svg?originalFilename=de_lu%C3%8C%C2%88pold_reinigungen_2c_rgb.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramersO_0ZxrLy, [])